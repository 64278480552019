import React from 'react';
import { Breakpoint} from 'react-socks';
import './Header.scss';
import {NavLink} from "react-router-dom";
import MobileNavigation from "./navigation/MobileNavigation";


function Header() {
    
    return (
        <header className={"hiro-header"}>
            <div className={"hiro-logo-wrapper"}>
                <img alt={"Hiro Logo"} src={"/images/hiro-logo-white.svg"}/>
            </div>
            <Breakpoint large up>
                <nav className={"hiro-navigation"}>
                    <NavLink to="/" className={"hiro-navigation-item"}>Home</NavLink>
                    <NavLink to="/jobs"  className={"hiro-navigation-item"}>Jobs</NavLink>
                    <NavLink to="/about"  className={"hiro-navigation-item"}>About</NavLink>
                    <NavLink to="/contact-us"  className={"hiro-navigation-item"}>Contact Us</NavLink>
                    <a href="tel:833-233-6398" className={"hiro-phone"}>833-233-6398</a>
                </nav>
            </Breakpoint>
            <Breakpoint medium down>
                <MobileNavigation></MobileNavigation>
            </Breakpoint>
        </header>
    );
}

export default Header;
 