import React from 'react';
import Page from "../common/page/Page";
import JobsIntro from "../sections/jobs/jobs-intro/JobsIntro";


function Jobs() {
    return (
        <Page>
          <JobsIntro></JobsIntro>
        </Page>
    );
}

export default Jobs;
 