import React from 'react';
import './HowItWorks.scss';
import Button from "../../common/button/Button";
import Section from "../../common/section/Section";
import SectionHeading from "../../common/section-heading/SectionHeading";
import {Breakpoint} from "react-socks";
import SectionActions from "../../common/section-actions/SectionActions";
import {useNavigate} from "react-router-dom";


function HowItWorks() {

    const navigate = useNavigate();

    const navigateTo = (path:string) => {
        navigate(path);
    }
    
    return (
        <Section id={"how-hiro-works"} className={"how-hiro-works"} 
                 background={"image"} 
                 imageUrl={"/images/hero-white.jpg"} 
                 centered={true}
                 restrict={true}
                 restrictType={"container"}>
            <SectionHeading heading={"How Hirō Works"}
                            subtitle={<>time to be a <span className={"accent"}><strong>hirō</strong></span></>}
                            description={["Business success relies on the right team. Our specialized recruitment services can build it for you. Hirō Tech Talent places full-time candidates in software development roles using a unique recruitment process developed through years of experience and knowledge in the technology industry."]}>
                <Breakpoint tablet down>
                    <SectionActions>
                        <Button handleClick={() => navigateTo("about")}>About Us</Button>
                    </SectionActions>
                </Breakpoint>
            </SectionHeading>
        </Section>
    );
}

export default HowItWorks;
 