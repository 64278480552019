import React, {CSSProperties, ReactNode, useRef} from 'react';
import './SectionHeading.scss';
import Paragraph from "../../sections/common/section/paragraph/Paragraph";
import {motion, useInView} from 'framer-motion';

type SectionHeadingProps = {
    heading: ReactNode|string;
    subtitle: ReactNode;
    sectionLink?:ReactNode;
    split?:boolean;
    description: string[];
    children: ReactNode;
};

const SectionHeading: React.FC<SectionHeadingProps> = (props) => {
    let classes: string[] = ["hiro-page-section-heading"];
    let style: CSSProperties = {};

    const ref = useRef(null);
    const isVisible = useInView(ref, {once:true});
    
    var splitDescription:boolean = false;
    
    if(props.split)
        splitDescription = true
    
    var descriptionContent = <>
        <div ref={ref} ></div>
        <div className={splitDescription ? "hiro-split-description":""}>
            <div className={"hiro-section-description-col"}>
            {
                props.description.filter((value, index)=>{
                    return index < (splitDescription ? 2 : index + 1);
                }).map((paragraph, index) => {
                    return (
                        <motion.div
                            initial={{opacity: 0, y: "15%"}}
                            animate={isVisible ? {opacity: 1, y: "0%"} : {}}
                            transition={{duration: .6, delay: .3}}>
                            <Paragraph>{paragraph}</Paragraph>
                        </motion.div>
                    )
                })
            }</div>
            {splitDescription ? <div className={"hiro-section-description-col"}>
                {
                    props.description.filter((value, index)=>{
                        return index > (splitDescription ? 1 : index + 1);
                    }).map((paragraph, index) => {
                        return (
                            <motion.div
                                initial={{opacity: 0, y: "15%"}}
                                animate={isVisible ? {opacity: 1, y: "0%"} : {}}
                                transition={{duration: .6, delay: .3}}>
                                <Paragraph>{paragraph}</Paragraph>
                            </motion.div>
                        )
                    })
                }
            </div> : null}
        </div>
    </>
    
    return (
        <div className={classes.join(" ")} style={style}>
            <motion.div
                initial={{opacity: 0, y: "15%"}}
                animate={isVisible ? {opacity: 1, y: "0%"} : {}}
                transition={{duration: .6, delay: 0}}
                className={"hiro-titles"}>
                <h3 className={"hiro-subtitle"}>time to be a <span className={"accent"}><strong>hirō</strong></span>
                </h3>
                <div className={props.sectionLink ? "hiro-title-split-action" : ""}>
                    <h1 className={"hiro-title"}>{props.heading}</h1>
                    {props.sectionLink ? props.sectionLink : null}
                </div>
            </motion.div>
            {descriptionContent}
            <motion.div   initial={{opacity: 0, y: "15%"}}
                          animate={isVisible ? {opacity: 1, y: "0%"} : {}}
                          transition={{duration: .6, delay: .6}}>
                {props.children}
            </motion.div>
            
        </div>
    );
}

export default SectionHeading;
