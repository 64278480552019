import React, {useRef} from 'react';
import './IntroGraphic.scss';
import Button from "../../../common/button/Button";
import {motion, useInView} from 'framer-motion';
import {useNavigate} from "react-router-dom";

function IntroGraphic() {
    const ref = useRef(null);
    const isVisible = useInView(ref, {once:true});

    const navigate = useNavigate();

    const handleItemClick = (id:string) => {
        const ref = document.getElementById(id)
        ref?.scrollIntoView({ behavior: 'smooth' });
    };

    const navigateTo = (path:string) => {
        navigate(path);
    }
    
    return (
        <div className={"hiro-candidate-card"} style={{backgroundImage: 'url("/images/hero-white.jpg")'}}>
            <div ref={ref}></div>
            <motion.div
                initial={{opacity:0, scale: .7}}
                animate={isVisible ? {opacity:1, scale: 1} : {}}
                transition={{type: "spring", stiffness: 150, delay: .9}}
                className={"hiro-graphic-left"} >
                <img alt={"Candidate"} src={"/images/candidates/candidate-b.jpg"}/>
                <h3>Hirō Candidate</h3>
                <div>
                <h2>Jessica Adebayo</h2>
                    <span className={"hiro-candidate-card-title"}>Senior Software Engineer</span>
                </div>
                
            </motion.div>
            <motion.div
                initial={{opacity:0, scale: .7}}
                animate={isVisible ? {opacity:1, scale: 1} : {}}
                transition={{type: "spring", stiffness: 150, delay: 1.2}}
                className={"hiro-graphic-right"}>
                <Button handleClick={()=>{handleItemClick("contact-us")}}>Hire Talent</Button>
                <Button handleClick={() => {navigateTo("/jobs")}} ghost={true}>Get Hired</Button>
            </motion.div>
           
        </div>
    );
}

export default IntroGraphic;
 