import React, {MouseEventHandler, ReactNode} from 'react';
import './Button.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

type ButtonProps = {
    ghost?:boolean;
    disabled?:boolean;
    loading?:boolean;
    children: ReactNode;
    handleClick:MouseEventHandler<HTMLButtonElement>;
};


const Button: React.FC<ButtonProps> = (props) => {


    let loadingIcon =  null;
    if(props.loading)
        loadingIcon =<FontAwesomeIcon className={"hiro-button-loading-icon"} icon={faSpinner} spin={true} />;

    if(props.ghost){
        return (
            <button className="hiro-button hiro-button-ghost" disabled={props.disabled} onClick={props.handleClick}>{loadingIcon}{props.children}</button>
        )
    }else{
        return (
            <button className="hiro-button" disabled={props.disabled} onClick={props.handleClick}>{loadingIcon}{props.children}</button>
        );
    }
}

export default Button;
