import React, {CSSProperties, ReactNode} from 'react';
import './Section.scss';


type SectionProps = {
    id?: string;
    className?: string;
    centered?: boolean;
    restrict?: boolean;
    full?:boolean;
    restrictType?: "container" | "content";
    background?: "primary" | "secondary" | "dark" | "image";
    imageUrl?: string;
    children: ReactNode;
    lowerContent?: ReactNode;
};

const Section: React.FC<SectionProps> = (props) => {

    let classes: string[] = ["hiro-page-section"];
    let style: CSSProperties = {};

    if (props.className)
        classes.push(props.className)

    if (props.restrict)
        classes.push("is-restricted");

    if (props.centered)
        classes.push("is-centered");

    if (props.full)
        classes.push("is-full-content");


    if (!props.restrictType || props.restrictType === "container")
        classes.push("restrict-container");

    if (props.restrictType === "content")
        classes.push("restrict-content");

    if (props.background && props.background === "primary")
        classes.push("is-primary");

    if (props.background && props.background === "secondary")
        classes.push("is-secondary");

    if (props.background && props.background === "dark")
        classes.push("is-dark");

    if (props.background && props.background === "image") {
        classes.push("is-image");
        style.backgroundImage = "url('" + props.imageUrl + "')";
    }


    var lowerContent = props.lowerContent ? <>
        <div className={"hiro-section-lower-content"}>
            {props.lowerContent}
        </div>
    </> : null;

    return (
        <section id={props.id} className={classes.join(" ")} style={style}>
            <div className={"hiro-section-content"}>
                <div className={"hiro-upper-content"}>
                    {props.children}
                </div>
                <div className={"hiro-lower-content"}>
                    {lowerContent}
                </div>
            </div>
        </section>
    );
}

export default Section;
