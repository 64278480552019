import React, {useRef} from 'react';
import './RolesWeSupply.scss';
import Avatar from "../../../common/avatar/Avatar";
import Section from "../../../common/section/Section";
import SectionHeading from "../../../common/section-heading/SectionHeading";
import {motion, useInView} from 'framer-motion';

interface IRole {
    roleName: string;
    candidateName: string;
    imageName: string;
}
 
function RolesWeSupply() {

    const ref = useRef(null);
    const isVisible = useInView(ref, {once: true});

    const roles: IRole[] = [
        {
            roleName: 'Software Developers and Architects',
            candidateName: '',
            imageName: '/images/candidates/candidate-a.jpg'
        },
        {
            roleName: 'Business Analysts',
            candidateName: '',
            imageName: '/images/candidates/candidate-b.jpg'
        },
        {
            roleName: 'Quality Assurance Testers',
            candidateName: '',
            imageName: '/images/candidates/candidate-j.jpg'
        },
        {
            roleName: 'Project Managers',
            candidateName: '',
            imageName: '/images/candidates/candidate-d.jpg'
        },
        {
            roleName: 'Scrum Masters',
            candidateName: '',
            imageName: '/images/candidates/candidate-e.jpg'
        },
        {
            roleName: 'UI/UX Designers and Developers',
            candidateName: '',
            imageName: '/images/candidates/candidate-f.jpg'
        },
        {
            roleName: 'Database Developers and Architects',
            candidateName: '',
            imageName: '/images/candidates/candidate-c.jpg'
        },
        {
            roleName: 'Business Intelligence Experts',
            candidateName: '',
            imageName: '/images/candidates/candidate-h.jpg'
        },
        {
            roleName: "Software Development Managers and Directors",
            candidateName: '',
            imageName: '/images/candidates/candidate-g.jpg'
        },
    ];

    let rolesHtml = [];
    for (let i = 0; i < roles.length; i++) {
        rolesHtml.push(<motion.div
            initial={{opacity: 0, y: "15%"}}
            animate={isVisible ? {opacity: 1, y: "0%"} : {}}
            transition={{duration: .6, delay: i <= 2 ? 0 : i <= 5 ? .3: .6}}
            className={"hiro-roles-we-supply-list-item"}>
            <Avatar image={roles[i].imageName} size={"medium"} reverse={true} name={roles[i].candidateName}
                    position={roles[i].roleName}></Avatar>
        </motion.div>)
    }

    var lowerRow =
        <>
            <div ref={ref}></div>
            <div className={"hiro-roles-we-supply-list"}>
                {rolesHtml}
            </div>
        </>;
    return (
        <Section className={"roles-we-supply"} lowerContent={lowerRow}>

            <SectionHeading heading={<>Roles We <span className={"accent"}>Supply</span></>}
                            subtitle={<>The right <span className={"accent"}>fit</span></>}
                            description={["At Hirō Tech Talent, we only offer recruitment services for software development roles. Many members of the Hirō team have worked as or worked with many of the positions we help businesses fill. This intimate experience of the roles we recruit for gives us a unique recruitment perspective. We know the right questions to ask to ensure we connect businesses with candidates who have technical expertise and align with a company's values and long-term goals."]}>
            </SectionHeading>

        </Section>
    );
}

export default RolesWeSupply;
 