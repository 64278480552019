import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import Home from "./pages/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Jobs from "./pages/Jobs";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import {ToastContainer} from "react-toastify";
import {BreakpointProvider, setDefaultBreakpoints} from 'react-socks';
import {PrimeReactProvider} from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
setDefaultBreakpoints([
    {xsmall: 0}, // all mobile devices
    {small: 576}, // mobile devices (not sure which one's this big)
    {medium: 768}, // ipad, ipad pro, ipad mini, etc
    {large: 1200}, // smaller laptops
    {xlarge: 1400} // laptops and desktops
]);

root.render(
    <React.StrictMode>
        <PrimeReactProvider>
            <BreakpointProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home></Home>}/>
                        <Route path="/jobs" element={<Jobs></Jobs>}/>
                        <Route path="/about" element={<AboutUs></AboutUs>}/>
                        <Route path="/contact-us" element={<ContactUs></ContactUs>}/>
                    </Routes>
                </BrowserRouter>
            </BreakpointProvider>
        </PrimeReactProvider>
        <ToastContainer/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
