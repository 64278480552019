import React from 'react';
import './HireWithHiro.scss';
import SectionHeading from "../../common/section-heading/SectionHeading";
import Section from "../../common/section/Section";
import HireWithHiroGraphic from "./hire-with-hiro-graphic/HireWithHiroGraphic";
function HireWithHiro() {
    
    var descriptions:string [] =[
        "As your recruitment partner, we become an extension of your business, introducing you to the best candidates possible. We use our diverse technology knowledge and experience working with companies across various industries to find you the best talent.",
        "Our recruitment process is more than a single interview and a glance at a resume. We take the time to build a relationship with your company and understand your technical requirements and business culture so we can place the right candidate within any of your software development roles.",
        "Every candidate we put forward needs to be a good fit. Our four-step process helps us learn about the company, candidate, and job criteria to ensure we deliver the most qualified candidate–every time. "
    ]
    
    return (
        <Section id={"hire-with-hiro"} className={"hire-with-hiro"} lowerContent={<HireWithHiroGraphic></HireWithHiroGraphic>}>
            <SectionHeading heading={<>Hire Talent With <span className={"accent"}>Hirō</span></>}
                            subtitle={<>Web <span className={"accent"}>Mobile</span> Cloud <span className={"accent"}>Integration</span> UI/UX</>}
                            split={true}
                            description={descriptions}>
            </SectionHeading>
            
        </Section>
    );
}

export default HireWithHiro;
 
