import React from 'react';
import './AboutUsIntro.scss';
import SectionHeading from "../../../common/section-heading/SectionHeading";
import Section from "../../../common/section/Section";


function AboutUsIntro() {
    return (
        <Section className={"about-us-intro"}
        centered={true}>
            <SectionHeading heading={<>About Us</>}
                            subtitle={<>Turning Qualified Candidates Into <span className={"accent"}><strong>hirōs</strong></span></>}
                            description={["At Hirō Tech Talent, we rely on our experienced recruitment team and developer-led vetting process to identify and place qualified candidates into full-time software technology positions."]}>
                
            </SectionHeading>
        </Section>
    );
}

export default AboutUsIntro;
 