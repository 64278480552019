import React, {ReactNode} from "react";
import './Paragraph.scss';

type ParagraphProps = {
    children: ReactNode;
    shade?: undefined | 'dark';
};
const Paragraph: React.FC<ParagraphProps> = (props) => {

    return (
        <p className={"hiro-paragraph " + (props.shade ? 'is-dark':'')}>
            {props.children}
        </p>
    );
}

export default Paragraph;