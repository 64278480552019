import React from 'react';
import './Avatar.scss';

type AvatarProps = {
    image?: string;
    size:'large'|'medium'|'small';
    reverse?:boolean;
    name:string;
    position:string;
};


const Avatar: React.FC<AvatarProps> = (props) => {

    const classes = ["avatar hiro-avatar"];
    if(props.size)
        classes.push(`is-${props.size}`);
    
    if(props.reverse)
        classes.push(`is-reversed`);
    
    return (
        <div className={classes.join(" ")}>
            <img className={"hiro-avatar-image"} src={props.image} alt="Hirō Tech Talent"/>
            <div className={"hiro-avatar-description"}>
                <span className={"hiro-avatar-name"}>{props.name}</span>
                <div className={"hiro-avatar-meta"}>
                <span className={"hiro-avatar-title"}>{props.position}</span>
                </div>
            </div>
        </div>
    );
}

export default Avatar;
 