import React from 'react';
import './Process.scss';
import Section from "../../../common/section/Section";
import SectionHeading from "../../../common/section-heading/SectionHeading";
import Tiles, {ITile} from "../../common/tiles/Tiles";



function Process() {

    const steps: ITile[] = [
        {
            title: "Two-Step Discovery",
            description: "Our recruitment specialist speaks with your team to understand the requirements of the role you are looking to fill. Next, a senior software engineer speaks with your hiring manager to understand the technologies, team, and processes you use to develop software. This two-step discovery process provides our team insight into your business culture and the technical skills and experience required to fill your posted role. "
        },
        {
            title: "Talent Search",
            description: "Our Hirōs recruit tech talent from our extensive network of highly qualified software professionals. This network provides a diverse pool of prospects for our team to tap into. Using learnings from the two-step discovery phase, we identify candidates that align with your business technology and culture. "
        },
        {
            title: "Technical Interview",
            description: "Interviewing candidates from a human resources perspective differs significantly from interviewing from a developer’s perspective. Our technical interview is a big part of what differentiates us from other recruiters. Our senior software professionals ensure prospects have the technical skills to match your business's needs. We won’t waste your time with candidates who are not the right fit. "
        },
        {
            title: "Candidate Delivery",
            description: "Once a candidate passes the technical interview, we set up an interview with the qualified candidate and your hiring team. This step allows you to directly assess their compatibility with your team dynamics and project requirements, ensuring a seamless integration of top technical talent into your organization. "
        }
    ];
    
    return (
        <Section className={"process"}
                 lowerContent={<Tiles tiles={steps}></Tiles>}>
            <SectionHeading heading={<>The <span className={"accent"}>Process</span></>}
                            subtitle={<>time to be a <span className={"accent"}><strong>hirō</strong></span></>}
                            description={["Business success relies on the right team. Our specialized recruitment services can build it for you. Hirō Tech Talent places full-time candidates in software development roles using a unique recruitment process developed through years of experience and knowledge in the technology industry."]}>
            </SectionHeading>

        </Section>
    );
}

export default Process;
 