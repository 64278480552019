import React from 'react';
import Page from "../common/page/Page";
import ContactUsIntro from "../sections/contact-us/contact-us-intro/ContactUsIntro";


function ContactUs() {
    return (
        <Page>
            <ContactUsIntro></ContactUsIntro>
        </Page>
    );
}

export default ContactUs;
 