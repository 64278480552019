import React, {useState} from 'react';
import './MobileNavigation.scss';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons'

function MobileNavigation() {
    var [showNavigation, setShowNavigation] =useState<boolean>(false);

    var navigation = <div>
        <nav className={"hiro-navigation"}>
            <FontAwesomeIcon className={"hiro-close-mobile-navigation-trigger"} icon={faTimes} onClick={()=>setShowNavigation(false)}></FontAwesomeIcon>
            <NavLink to="/" className={"hiro-navigation-item"}>Home</NavLink>
            <NavLink to="/jobs"  className={"hiro-navigation-item"}>Jobs</NavLink>
            <NavLink to="/about"  className={"hiro-navigation-item"}>About</NavLink>
            <NavLink to="/contact-us"  className={"hiro-navigation-item"}>Contact Us</NavLink>
            <a href="tel:833-233-6398" className={"hiro-phone"}>833-233-6398</a>
        </nav>
    </div>
    
    return (
        <div className={"hiro-mobile-navigation"}>
            <div className={"hiro-mobile-navigation-trigger"}>
               <FontAwesomeIcon icon={faBars} onClick={()=>setShowNavigation(true)}></FontAwesomeIcon>
            </div>
            {showNavigation ? navigation : null}
        </div>
    );
}

export default MobileNavigation;
 