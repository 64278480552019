import React, {ReactNode} from 'react';
import './Page.scss';
import {useCurrentBreakpointName} from "react-socks";
import Header from '../../sections/header/Header';
import ScrollSpy from "react-ui-scrollspy";

type PageProps = {
    children: ReactNode;
};

const Page: React.FC<PageProps> = (props) => {
    const classes:string[] = ["hiro-page"];

    const breakpoint = useCurrentBreakpointName();
    if (breakpoint === 'small' || breakpoint === 'xsmall')
        classes.push("is-mobile");

    if (breakpoint === 'medium')
        classes.push("is-tablet");

    if (breakpoint === 'large')
        classes.push("is-large");

    if (breakpoint === 'xlarge')
        classes.push("is-xlarge");
    
    return (
        <div className={classes.join(" ")}>
            <ScrollSpy activeClass={"is-active"}>
            <Header></Header>
            {props.children}
            </ScrollSpy>
        </div>
    );
}

export default Page;
