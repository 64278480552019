import React, {CSSProperties, ReactNode} from 'react';
import './SectionActions.scss';

type SectionActionProps = {
    children: ReactNode;
};

const SectionActions: React.FC<SectionActionProps> = (props) => {

    let classes:string[] = ["hiro-actions"];
    let style:CSSProperties = {};
    
    return (
        <div  className={classes.join(" ")} style={style}>
            {props.children}
        </div>
    );
}

export default SectionActions;
