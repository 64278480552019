import React, {ReactNode, useRef} from "react";
import './Tiles.scss';
import Paragraph from "../section/paragraph/Paragraph";
import {motion, useInView} from 'framer-motion';

type TilesProps = {
    tiles:ITile[];
};
const Tiles: React.FC<TilesProps> = (props) => {

    const ref = useRef(null);
    const isVisible = useInView(ref, {once:true});

    
    let stepsHtml = [];
    for (let i = 0; i < props.tiles.length; i++) {
        stepsHtml.push(<motion.div
            initial={{opacity: 0, y: "15%"}}
            animate={isVisible ? {opacity: 1, y: "0%"} : {}}
            transition={{duration: .6, delay: .3}}
            className={"hiro-tile"}>
            <span className={"hiro-tile-number"}>{i + 1}</span>
            <div className={"hiro-tile-description"}>
                <h3 className={"hiro-tile-title"}><span
                    className={"hiro-tile-prefix"}>Step {i + 1}</span>{props.tiles[i].title}</h3>
                <Paragraph>{props.tiles[i].description} {props.tiles[i].link ? props.tiles[i].link : null}</Paragraph>
                
            </div>
        </motion.div>)
    }
    
    return (
        <>
            <div ref={ref} ></div>
        <div className={"hiro-tiles"}>
            {stepsHtml}
        </div>
        </>
    );
}

export default Tiles;


export interface ITile {
    title: string;
    description: string;
    link?: ReactNode;
}