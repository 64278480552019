import React from 'react';
import Intro from "../sections/intro/Intro";
import HowItWorks from "../sections/how-it-works/HowItWorks";
import HireWithHiro from "../sections/hire-with-hiro/HireWithHiro";
import Process from "../sections/hire-with-hiro/process/Process";
import HireWithHiroCta from "../sections/hire-with-hiro/hire-with-hiro-cta/HireWithHiroCta";
import RolesWeSupply from "../sections/hire-with-hiro/roles-we-supply/RolesWeSupply";
import GetHiredWithHiro from "../sections/get-hired-with-hiro/GetHiredWithHiro";
import ContactUs from "../sections/contact-us/contact-us-form/ContactUsForm";
import Page from "../common/page/Page";


function Home() {
    return (
        <Page>
            <Intro></Intro>
            <HowItWorks></HowItWorks>
            <div id={"hire-with-hiro-wrapper"}>
                <HireWithHiro></HireWithHiro>
                <Process></Process>
                <HireWithHiroCta></HireWithHiroCta>
                <RolesWeSupply></RolesWeSupply>
            </div>
            <GetHiredWithHiro></GetHiredWithHiro>
            <ContactUs></ContactUs>
        </Page>
    );
}

export default Home;
 