import React from 'react';
import './HireWithHiroGraphic.scss';

function HireWithHiroGraphic() {
    return (
        <div className="hire-with-hiro-graphic" style={{backgroundImage: 'url("/images/hero-white.jpg")'}}>
            <div className={"hire-with-hiro-content"}>
                 <span className={"hiro-our-process-technical-graphic"}>
                    <img alt={"Angular Logo"} className={"hiro-our-process-logo"}
                         src={"/images/logos/angular-white.svg"}/>
                    <img alt={"Azure DevOps Logo"} className={"hiro-our-process-logo"}
                         src={"/images/logos/devops-white.svg"}/>
                    <img alt={"GitHub Logo"} className={"hiro-our-process-logo"}
                         src={"/images/logos/github-white.svg"}/>
                </span>
                <img alt={"Candidate"} className={"hiro-our-process-candidate"} src={"/images/candidates/candidate-a.jpg"}/>
                <img alt={"Candidate"} className={"hiro-our-process-candidate"} src={"/images/candidates/candidate-d.jpg"}/>
                <img alt={"Candidate"} className={"hiro-our-process-candidate"} src={"/images/candidates/candidate-f.jpg"}/>
                <img alt={"Candidate"} className={"hiro-our-process-infographic-img"} src={"/images/our-process.png"}/>
            </div>
        </div>
    );
}

export default HireWithHiroGraphic;
 
