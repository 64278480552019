import React from 'react';
import './AboutUsCompany.scss';
import Section from "../../../common/section/Section";
import SectionHeading from "../../../common/section-heading/SectionHeading";
import {Breakpoint} from "react-socks";


function AboutUsCompany() {
    //style={{backgroundImage: 'url("/images/hero.jpg")'}}
    return (
        <Section
            id={"about-us"}
            className={"about-us"}
            background={"image"}
            imageUrl={"/images/hero-white.jpg"}
            restrict={true}
            restrictType={"container"}>
            <SectionHeading heading={<>We're Here To <br/>Make A Difference</>}
                            subtitle={<>About <span className={"accent"}><strong>hirō</strong></span></>}
                            description={["After decades of working within the technology sector, we have seen countless businesses struggle to hire and retain good software development talent. From large enterprises to start-up companies, a lengthy hunt for the right full-time technology talent can drain resources and delay business objectives.",
                                "We created Hirō to meet the needs of businesses that failed to fill full-time software development roles. These businesses often don't understand their technology needs or lack the human resources team to identify and entice a technical candidate.",
                                "Our years as software consultants and developers give us a unique recruitment perspective. Our team has the skills to identify business technology requirements, and we know how to find and vet the right tech talent to fill them."]}>
            </SectionHeading>
            <Breakpoint xlarge up>
                <div className={"hiro-intro-graphic"}>
                    <img alt={"Excited Candidate"} className={"hiro-about-us-image"} src={"/images/excited-about-us.jpg"}/>
                </div>
            </Breakpoint>
        </Section>
    );
}

export default AboutUsCompany;
 