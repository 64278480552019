import React from 'react';
import './Intro.scss';
import Button from "../../common/button/Button";
import Section from "../../common/section/Section";
import SectionHeading from "../../common/section-heading/SectionHeading";
import SectionActions from "../../common/section-actions/SectionActions";
import IntroGraphic from "./intro-graphic/IntroGraphic";
import {Breakpoint, useCurrentBreakpointName} from "react-socks";
import {useNavigate} from "react-router-dom";


function Intro() {

    const navigate = useNavigate();
    
    const handleItemClick = (id:string) => {
        const ref = document.getElementById(id)
        ref?.scrollIntoView({ behavior: 'smooth' });
    };
    
    const navigateTo = (path:string) => {
        navigate(path);
    }

    
    const breakpoint = useCurrentBreakpointName();
    let heading = <>A Developer-Led <br/>Technology Recruitment Firm</>;
    if (breakpoint ==='small' || breakpoint === 'xsmall')
        heading = <>A Developer-Led <br/>Technology Recruitment Firm</>;

    if (breakpoint === 'medium')
        heading = <>A Developer-Led <br/>Technology Recruitment Firm</>;

    if (breakpoint === 'large')
        heading = <>A Developer-Led <br/>Technology Recruitment Firm</>;
    
    
    return (
        <Section className={"intro"}>
            <SectionHeading heading={heading}
                            subtitle={<>time to be a <span className={"accent"}><strong>hirō</strong></span></>}
                            description={["At Hirō Tech Talent, we rely on our experienced recruitment team and developer-led vetting process to identify and place qualified candidates into full-time software technology positions."]}>
                <Breakpoint tablet down>
                <SectionActions>
                    <Button handleClick={()=>{handleItemClick("contact-us")}}>Hire Talent</Button>
                    <Button handleClick={() => {navigateTo("/jobs")}} ghost={true}>Get Hired</Button>
                </SectionActions>
                </Breakpoint>
            </SectionHeading>
            <Breakpoint large up>
            <div className={"hiro-intro-graphic"}>
               <IntroGraphic></IntroGraphic>
            </div>
            </Breakpoint>
        </Section>

    );
}

export default Intro;
 