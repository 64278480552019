import React from 'react';
import Page from "../common/page/Page";
import AboutUsCompany from "../sections/about-us/about-us-company/AboutUsCompany";
import AboutUsIntro from "../sections/about-us/about-us-intro/AboutUsIntro";
import ContactUs from "../sections/contact-us/contact-us-form/ContactUsForm";


function AboutUs() {
    return (
        <Page>
            <AboutUsIntro></AboutUsIntro>
            <AboutUsCompany></AboutUsCompany>
            <ContactUs></ContactUs>
        </Page>
    );
}

export default AboutUs;
 