import React from 'react';
import './ContactUsForm.scss';
import Section from "../../../common/section/Section";
import SectionHeading from "../../../common/section-heading/SectionHeading";
import {Breakpoint} from "react-socks";
import ContactForm from "../../common/contact-form/ContactForm";

function ContactUs() {
    
    let heading = <>Get Started Today</>;
    return (
        <Section id={"contact-us"} className={"contact-us"}
                 lowerContent={<ContactForm></ContactForm>}
        centered={true}>
            <SectionHeading heading={heading}
                            subtitle={<>time to be a <span className={"accent"}><strong>hirō</strong></span></>}
                            description={["Fill out the form below or call 833-233-6398 to speak with our lead recruitment specialist today."]}>
                <Breakpoint tablet down>
                </Breakpoint>
            </SectionHeading>
            
        </Section>

    );
}

export default ContactUs;
 