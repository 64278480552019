import React from 'react';
import './HireWithHiroCta.scss';
import Button from "../../../common/button/Button";
import Section from "../../../common/section/Section";
import SectionHeading from "../../../common/section-heading/SectionHeading";
import SectionActions from "../../../common/section-actions/SectionActions";


function HireWithHiroCta() {
    const handleItemClick = (id:string) => {
        const ref = document.getElementById(id)
        ref?.scrollIntoView({ behavior: 'smooth' });
    };
    
    return (
    <Section className={"hire-with-hiro-cta"}
             background={"image"}
             imageUrl={"/images/hero-white.jpg"}
             centered={true}
             restrict={true}
             restrictType={"container"}>
        <SectionHeading heading={<>Put Us To Work For You</>}
                        subtitle={<>Let's Get <span className="accent">Started</span></>}
                        description={[]}>
            <SectionActions>
                <Button handleClick={() => handleItemClick("contact-us")}>Get Started</Button>
            </SectionActions>
        </SectionHeading>
    </Section>
    );
}

export default HireWithHiroCta;
 