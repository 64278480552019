import React from 'react';
import './GetHiredWithHiro.scss';
import SectionHeading from "../../common/section-heading/SectionHeading";
import Section from "../../common/section/Section";
import Tiles, {ITile} from "../common/tiles/Tiles";
import {NavLink, useNavigate} from "react-router-dom";
import Button from "../../common/button/Button";
import SectionActions from "../../common/section-actions/SectionActions";


function GetHiredWithHiro() {

    const navigate = useNavigate();

    const navigateTo = (path:string) => {
        navigate(path);
    }
    
    const steps: ITile[] = [{
        title:"Checkout Our Open Positions",
        description:"Check out our open positions: view and apply for available jobs of interest.",
        link: <NavLink to={"jobs"}>View Jobs.</NavLink>
    },
        {
            title:"Get In Touch",
            description:"When skills and interests align with a position, our recruitment team will contact you for an informal interview. This interview will consist of a high-level assessment of your technical stack knowledge and identify your career goals. "
        },
        {
            title:"Technical Interview",
            description:"If the recruitment team qualifies you, the next stage involves interviewing with one of our expert software developers. This interview aims to assess your proficiency in the necessary tech stack for the job and to ensure you are a suitable fit for the position."
        },
        {
            title:"Client Interview",
            description:"If you successfully clear both the recruitment team and expert software developer interviews, you will move on to the final stage, which involves interviewing with the company's hiring team. This interview lets you understand the position's full scope and the company's culture."
        }];
   
    return (
            <Section id={"get-hired-with-hiro"} 
                     className={"get-hired-with-hiro"} 
                     full={true}
                     lowerContent={<Tiles tiles={steps}></Tiles>}>
                <SectionHeading heading={<>Get Hired With <span className={"accent"}>Hirō</span></>}
                                subtitle={<>Grow <span className={"accent"}>Thrive</span> Excel</>}
                                sectionLink={<><Button handleClick={()=>navigateTo("/jobs")}>View Jobs</Button></>}
                                split={true}
                                description={["The average person spends over 2,000 hours a year in a full-time position. At Hirō Tech Talent, we want to find you a place to fill those hours to help you grow and thrive personally and professionally.",
                                    "As your trusted recruiter, we work hard on your behalf to find the very best full-time tech opportunities. To make the right connection between you and your future career, you must be open to candid and technical interviews, discussing career aspirations, sharing information, and providing feedback during our recruitment process.",
                                    "When you apply for an open position and are interviewed by our recruitment specialists, you are placed into our candidate database. We keep your technical skills, work history, and interview results on file indefinitely. To ensure you are still interested in making a career change, our team will perform regular check-ins to curate and update your interest level and adjust any career goals."]}>
                </SectionHeading>
            </Section>
    );
}

export default GetHiredWithHiro;
 