import React, {useEffect, useRef, useState} from 'react';
import './ContactForm.scss';
import Button from "../../../common/button/Button";
import emailjs from "@emailjs/browser";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import ReCAPTCHA from "react-google-recaptcha";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Paragraph from "../../common/section/paragraph/Paragraph";
import {SelectButton} from "primereact/selectbutton";

function ContactForm() {

    const options = ['Company', 'Candidate'];
    const [value, setValue] = useState(options[0]);


    const submitButton = useRef<HTMLButtonElement>(null);
    useEffect(() => emailjs.init("wPx4xo3AZqPCQql-X"), []);
    const recaptcha = useRef<ReCAPTCHA | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);

    const handleSubmit = async (values: any) => {

        submitButton.current?.click();

        const serviceId = "service_off8s8n";
        const templateId = "template_x4jyo7j";

        const captchaValue = recaptcha.current?.getValue();
        if (!captchaValue) {
            toast.info("Don't forget to click verify you're human! ", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light"
            });
            return;
        }


        if (!values.email || values.email.endsWith("yahoo.com")) {
            toast.info("Sorry, those are invalid email domains.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light"
            });
            return;
        }

        try {


            setSubmitting(true);
            await emailjs.send(serviceId, templateId, {
                name: values.name,
                email: values.email,
                message: values.projectDetails,
                'g-recaptcha-response': captchaValue,
            });
            setSuccessfullySubmitted(true);

            // @ts-ignore
            window.lintrk('track', {conversion_id: 15129900});

        } catch (error) {
            toast.error("Oops, looks like there was an issue.  Please try again. ", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "dark",
            });
        } finally {
            setSubmitting(false);
        }
    };

    let formContent = <div className={"hiro-form-submitted"}>
        <FontAwesomeIcon icon={faCheckCircle}/>
        <h3>Request Sent</h3>
        <Paragraph>Your request was successfully sent! We'll be in touch shortly.</Paragraph>
    </div>;

    let isCandidate = value === "Candidate";

    if (!successfullySubmitted) {
        formContent = (<Formik
            initialValues={{name: '', email: '', projectDetails: ''}}
            validate={values => {
                const errors: any = {};
                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }

                if (!values.name) {
                    errors.name = 'Required';
                }

                if (!values.projectDetails) {
                    errors.projectDetails = 'Required';
                }

                return errors;
            }}
            onSubmit={(values, {setSubmitting}) => {

            }}
        >
            {props => (
                <Form autoComplete={"off"} className="hiro-contact-form">
                    <div className="hiro-contact-form-fields">
                        <div className="hiro-form-field">
                            <label className="hiro-form-field-label" htmlFor="hiro-ff-name">I am a...<span
                                className={"hiro-form-label-required-marker"}>*</span></label>
                            <SelectButton value={value} onChange={(e) => setValue(e.value)} options={options}/>
                        </div>
                        <div className="hiro-form-field">
                            <label className="hiro-form-field-label" htmlFor="hiro-ff-name">Name<span
                                className={"hiro-form-label-required-marker"}>*</span></label>
                            <Field id="hiro-ff-name" className="hiro-form-control hiro-form-control-text"
                                   type="text" name="name"/>
                            <ErrorMessage name="name" component="span" className="hiro-form-control-error"/>
                        </div>
                        <div className="hiro-form-field">
                            <label className="hiro-form-field-label" htmlFor="hiro-ff-email">Email<span
                                className={"hiro-form-label-required-marker"}>*</span></label>
                            <Field id="hiro-ff-email" className="hiro-form-control hiro-form-control-text"
                                   type="email" name="email"/>
                            <ErrorMessage name="email" component="span" className="hiro-form-control-error"/>
                        </div>
                        
                            <div className="hiro-form-field">
                                <label className="hiro-form-field-label" htmlFor="hiro-ff-project-details">{isCandidate ? "Tell Us About Yourself" :"How Can We Help?"} <span className={"hiro-form-label-required-marker"}>*</span></label>
                                <Field id="hiro-ff-project-details"
                                       className="hiro-form-control hiro-form-control-textarea" as="textarea"
                                       name="projectDetails"/>
                                <ErrorMessage name="projectDetails" component="span"
                                              className="hiro-form-control-error"/>
                            </div>
                        
                        <ReCAPTCHA ref={recaptcha}
                                   sitekey={process.env.REACT_APP_SITE_KEY ? process.env.REACT_APP_SITE_KEY : ''}/>
                    </div>
                    <footer className="hiro-contact-form-footer">
                        <Button loading={submitting} disabled={submitting}
                                handleClick={(e) => handleSubmit(props.values)}>Send</Button>
                        <button ref={submitButton} type="submit" style={{"display": "none"}}></button>
                    </footer>

                </Form>
            )}

        </Formik>);
    }
    return (
        formContent
    );
}

export default ContactForm;
 